<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              년월
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="getReportViewData"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="FILE"
                @click.native="onPrintClick"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false"/>
    </div>
  </div>
</template>

<script>

import InputDate from '@/components/common/datetime/InputDate';
import { getFormattedTelNumber } from "@/utils/string";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import moment from "moment";
import ReportView from '@/components/common/report/ReportView';
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {commonCodesGetBizMstInfo} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  DATE_FORMAT_YYYY_MM, getFormattedDate,
} from '@/utils/date';
import {mapGetters} from "vuex";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";

export default {
  name: "SalesStatusByPeriod",
  components: {
    InputDateRange,
    ReportView,
    ErpButton
  },
  async created() {
  },
  computed: {
    ...mapGetters(["username"]),
  },
  async mounted() {


    await this.getReportViewData();
  },
  data() {
    return {
      dateRange: {
        from: getFormattedDate(new Date()),
        to: getFormattedDate(new Date()),
      },
    };
  },
  methods: {
    async getReportViewData() {

      const {from,to} = this.dateRange;
      const {visitPer,salesStore,salesPrice,salesCard} = await GolfErpAPI.getReportSalesByPeriod(from,to);
      let storeDailyCardReportList = [];
      let tempData = [];
      const visitTotalPer = visitPer[0].visitTotalPer;
      salesStore.forEach((item)=>{
        item.totSalesTotAmt = item.feeSalesTotAmt + item.cartSalesTotAmt + item.grillSalesTotAmt + item.rentSalesTotAmt + item.teeSalesTotAmt;
        item.totSalesNetAmt = item.feeSalesNetAmt + item.cartSalesNetAmt + item.grillSalesNetAmt + item.rentSalesNetAmt + item.teeSalesNetAmt;
        item.totSalesVatAmt = item.feeSalesVatAmt + item.cartSalesVatAmt + item.grillSalesVatAmt + item.rentSalesVatAmt + item.teeSalesVatAmt;
        item.feeUnitPrice = this.replaceValidNumber((item.feeSalesTotAmt/visitTotalPer).toFixed(0));
        item.cartUnitPrice = this.replaceValidNumber((item.cartSalesTotAmt/visitTotalPer).toFixed(0));
        item.grillUnitPrice = this.replaceValidNumber((item.grillSalesTotAmt/visitTotalPer).toFixed(0));
        item.rentUnitPrice = this.replaceValidNumber((item.rentSalesTotAmt/visitTotalPer).toFixed(0));
        item.teeUnitPrice = this.replaceValidNumber((item.teeSalesTotAmt/visitTotalPer).toFixed(0));
        item.totUnitPrice = this.replaceValidNumber((item.totSalesTotAmt/visitTotalPer).toFixed(0));

      });
      salesCard.forEach((item, index) => {
        const isLastData = index === salesCard.length - 1;
        const no = (index % 4) + 1;
        const block = {};
        block[`purcName${no}`] = item.purcName;
        block[`salesCnt${no}`] = item.salesCnt;
        block[`salesAmt${no}`] = item.salesAmt;
        tempData.push(block);
        if (tempData.length === 4 || isLastData) {
          const totBlock = {};
          if (isLastData) {
            totBlock['purcName4'] = '합계';
            totBlock['salesCnt4'] = salesCard.reduce(
                (acc, cur) => acc + cur.salesCnt,
                0,
            );
            totBlock['salesAmt4'] = salesCard.reduce(
                (acc, cur) => acc + cur.salesAmt,
                0,
            );
            if (tempData.length === 4) {
              storeDailyCardReportList.push(Object.assign(...tempData));
              tempData = [];
            }
            tempData.push(totBlock);
          }
          storeDailyCardReportList.push(Object.assign(...tempData));
          tempData = [];
        }
      });
      // console.log(salesStore);
      const reportData = {
        reportJson: {
          jsonData: {visitPer,salesStore,salesPrice,storeDailyCardReportList},
          bsnDate : "일자:  "+ from + "  ~  " + to,
          username: this.username,
          uniqueCode: this.$options.name,
        }
      };

      // console.log(reportData);
      // console.log(JSON.stringify(reportData));

      await this.$refs.reportViewComponent.postReport(
          reportData,
          `${this.$options.name}`,
      );
    },
    onPrintClick() {
      this.$refs.reportViewComponent.printReport();
    },
    replaceValidNumber(val){
      // NaN And Infinity to 0
      return (isNaN(parseInt(val)) || !isFinite(val)) ? '0' : val;
    }
  }

};
</script>
